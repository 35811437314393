// src/services/apiService.ts

import { ChannelsResponse, ErrorResponse } from '../interfaces/ApiResponse';

class ApiService {
  private baseUrl: string | undefined;

  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
  }

  public async listChannels(inputValue: string): Promise<ChannelsResponse> {
    try {
      const url = new URL(`${this.baseUrl}/channels`);
      url.searchParams.append('search', inputValue);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorResponse: ErrorResponse = await response.json();
        throw new Error(errorResponse.message || 'Error fetching channels');
      }

      const data: ChannelsResponse = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        console.error("Failed to fetch from API", error.message);
        throw error;
      }
      throw new Error('An unexpected error occurred');
    }
  }
}

const apiService = new ApiService();
export default apiService;
