function TopBar() {
    return (
        <div className="bg-gray-700 text-white py-4 px-6 flex justify-between items-center">
        <div className="font-bold text-4xl">Virallizr</div> 
            <div className="flex items-center gap-4">
                <a style={{display:'none'}} href="/enroll" className="border border-white px-4 py-2 hover:bg-gray-600 hover:border-transparent hover:text-gray-700 rounded transition-colors">
                    Seja um profissional
                </a>
            </div>
        </div>
    );
}

export default TopBar;
