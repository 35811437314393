import React from 'react';
import './App.css';
import TopBar from './components/TopBar/TopBar';
import SearchBox from './components/SearchBox/SearchBox';
import ApiService from './services/apiService';

function App() {
  return (
    <div className="App">
      <TopBar />
      <SearchBox />
      
    </div>
  );
}

export default App;
