// components/SearchBox/SearchBox.js
import  { useState } from 'react';
import apiService from '../../services/apiService';
const marginBottom= '10vh'

function SearchBox() {
    const [inputValue, setInputValue] = useState('');
  
    
    const handleSearch = async () => {
        try {
            console.log(inputValue)
            const response = await apiService.listChannels(inputValue);
            console.log("Channels received:", response.channels);
        } catch (error) {
            console.error("Error fetching channels:", error);
        }
    };


    return (
        <div className="flex justify-center items-center min-h-screen" style={{ marginBottom: '7vh' }}>
            <div className="w-full max-w-xl relative mx-auto"> 
                <input
                    type="text"
                    placeholder="Faça a sua busca..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e)=> {
                        if (e.key=== 'Enter'){
                            handleSearch()
                        }
                    }}

                    className="w-full pl-10 pr-12 py-3 text-gray-900 bg-white border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{ height: '7vh',marginBottom:marginBottom }}
                />
                <button
                    onClick={handleSearch}
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    <svg
                        className="h-8 w-8 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{marginBottom: marginBottom}}
                    >
                        <circle cx="11" cy="11" r="8" strokeWidth="3"></circle>
                        <line x1="18" y1="18" x2="22" y2="22" strokeWidth="2" strokeLinecap="round"></line>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default SearchBox;
