import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement); // Added type assertion for TypeScript
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Pass a no-operation function if you're not using the onPerfEntry callback
reportWebVitals(() => {});
